var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"profile-basket-side-bar","visible":_vm.isProfileBasketSideBarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-profile-basket-side-bar-active', val); },"hidden":_vm.onHiddenSidebar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Свойства чека ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit.bind(null, _vm.openElementId))},"reset":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_c('b-form-group',{attrs:{"label":"Данные чека"}},[_c('b-row',[_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"номер фискального накопителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"input-live"}},[_vm._v("Номер фискального накопителя (ФН)")]),_c('b-form-input',{attrs:{"id":"fiscal-drive-number","autocomplete":"off","autofocus":"","trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.fiscalDriveNumber),callback:function ($$v) {_vm.$set(_vm.basketTicket, "fiscalDriveNumber", $$v)},expression:"basketTicket.fiscalDriveNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"номер фискального документа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"input-live"}},[_vm._v("Номер фискального документа (ФД)")]),_c('b-form-input',{attrs:{"id":"fiscal-document-number","type":"number","autocomplete":"off","trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.fiscalDocumentNumber),callback:function ($$v) {_vm.$set(_vm.basketTicket, "fiscalDocumentNumber", $$v)},expression:"basketTicket.fiscalDocumentNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"фискальный признак документа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"input-live"}},[_vm._v("Фискальный признак документа (ФП)")]),_c('b-form-input',{attrs:{"id":"fiscal-sign","type":"number","autocomplete":"off","trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.fiscalSign),callback:function ($$v) {_vm.$set(_vm.basketTicket, "fiscalSign", $$v)},expression:"basketTicket.fiscalSign"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"сумма чека","rules":{ required: _vm.required, regex: /^[1-9]+(\.\d{2})*(,\d{2})?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"input-live"}},[_vm._v("Сумма чека")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"bg-primary text-white h-100"},[_vm._v(" ₽ ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"total-sum","autocomplete":"off","trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.totalSum),callback:function ($$v) {_vm.$set(_vm.basketTicket, "totalSum", $$v)},expression:"basketTicket.totalSum"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('label',{attrs:{"for":"input-live"}},[_vm._v("Дата")]),_c('b-row',{staticClass:"d-flex justify-content-around"},[_c('validation-provider',{staticClass:"pr-1 col-7",attrs:{"rules":"required","name":"дата"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","type":"date","max":_vm.toDay,"trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.receiveDate[0]),callback:function ($$v) {_vm.$set(_vm.basketTicket.receiveDate, 0, $$v)},expression:"basketTicket.receiveDate[0]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"pl-1 col-5",attrs:{"rules":"required","name":"время"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time","type":"time","trim":"","disabled":!_vm.edit},model:{value:(_vm.basketTicket.receiveDate[1]),callback:function ($$v) {_vm.$set(_vm.basketTicket.receiveDate, 1, $$v)},expression:"basketTicket.receiveDate[1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),(_vm.isAdmin && (!_vm.edit && Number.isNaN(parseInt(_vm.basketTicket.companyId, 10))))?_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"button"},on:{"click":function($event){$event.preventDefault();_vm.edit = true}}},[_vm._v(" Редактировать ")])],1):_vm._e(),(_vm.edit && _vm.isAdmin)?_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Сохранить ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Отмена ")])],1):_vm._e()],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }