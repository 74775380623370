<template>
  <b-sidebar
    id="ticket-sidebar"
    :visible="isTicketSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="onShownSidebar()"
    @hidden="onHiddenSidebar"
    @change="(val) => $emit('update:is-ticket-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Кассовый чек
        </h5>

      </div>

      <b-overlay
        rounded="pill"
        :show="isLoading"
      >

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit.bind(null, openElementId))"
            @reset.prevent="clearForm"
          >

            <b-form-group label="Данные чека">
              <b-row>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="номер фискального накопителя"
                    rules="required"
                  >
                    <b-form-input
                      id="fiscal-drive-number"
                      v-model="elementData.fiscalDriveNumber"
                      placeholder="Номер фискального накопителя (ФН)"
                      autocomplete="off"
                      autofocus
                      trim
                    />

                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="номер фискального документа"
                    rules="required"
                  >
                    <b-form-input
                      id="fiscal-document-number"
                      v-model="elementData.fiscalDocumentNumber"
                      placeholder="Номер фискального документа (ФД)"
                      type="number"
                      autocomplete="off"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="фискальный признак документа"
                    rules="required"
                  >
                    <b-form-input
                      id="fiscal-sign"
                      v-model="elementData.fiscalSign"
                      placeholder="Фискальный признак документа (ФП)"
                      type="number"
                      autocomplete="off"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="сумма чека"
                    :rules="{ required, regex: /^[1-9]\d+(\.\d{2})*(,\d{2})?$/ }"
                  >
                    <b-input-group>
                      <template #append>
                        <b-input-group-text class="bg-primary text-white h-100">
                          ₽
                        </b-input-group-text>
                      </template>

                      <b-form-input
                        id="total-sum"
                        v-model="totalSum"
                        placeholder="Сумма чека"
                        autocomplete="off"
                        trim
                        @input="elementData.totalSum = currencyUnFormat(totalSum)"
                      />
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >
                  <b-row class="d-flex justify-content-around">

                    <validation-provider
                      v-slot="{ errors }"
                      class="pr-1 col-7"
                      rules="required"
                      name="дата"
                    >
                      <b-form-input
                        id="date"
                        v-model="elementData.date"
                        type="date"
                        :max="toDay"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      class="pl-1 col-5"
                      rules="required"
                      name="время"
                    >
                      <b-form-input
                        id="time"
                        v-model="elementData.time"
                        type="time"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-row>
                </b-col>

              </b-row>

            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Сохранить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>

          </b-form>
        </validation-observer>

      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BOverlay,
  BForm,
  BInputGroup,
  BInputGroupText,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from 'vee-validate'

import { regex } from 'vee-validate/dist/rules'
import ru from 'vee-validate/dist/locale/ru.json'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { element } from '@/application/basketService'
import { toISODateNowFormat } from '@/@core/utils/utils'
import { currencyUnFormat } from '@/@core/utils/filter'

localize('ru', ru)
extend('regex', regex)

export default {
  components: {
    BSidebar,
    BOverlay,
    BForm,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTicketSidebarActive',
    event: 'update:is-ticket-sidebar-active',
  },
  props: {
    isTicketSidebarActive: {
      type: Boolean,
      required: true,
    },
    openElementId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      totalSum: '',
      toDay: toISODateNowFormat(),
      currencyUnFormat,
    }
  },
  setup(props, { emit }) {
    const {
      isLoading,
      elementData,

      refFormObserver,
      clearForm,

      onShownSidebar,
      onHiddenSidebar,
      onSubmit,
    } = element(emit)
    return {
      isLoading,
      elementData,

      refFormObserver,
      clearForm,

      onShownSidebar,
      onHiddenSidebar,
      onSubmit,
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#create-element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
