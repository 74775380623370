<template>
  <div>
    <b-form-select
      class="mr-1"
      :value="basketIdUsed"
      :options="basketList"
      style="width: 70%;"
      @change="changingBasket"
    />

    <b-button
      v-if="basketCreatedIdUsed === userId && basketDefaultUsed !== 1"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="flat-primary"
      class="btn-icon rounded-circle"
      @click="removeBasket"
    >
      <feather-icon icon="Trash2Icon" />
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="flat-primary"
      class="btn-icon rounded-circle"
      @click="createBasket"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { basketChange, createBasket, deleteBasket } from '@/application/basketService'

export default {
  components: {
    BButton,
    BFormSelect,

  },
  directives: {
    Ripple,
  },
  computed: {
    basketList() {
      return (this.$store.state.basket.baskets || []).length > 0 ? this.$store.state.basket.baskets.map(x => ({ value: x.id, text: x.title })) : []
    },
    basketIdUsed() {
      return this.$store.state.basket.basket?.id || null
    },
    basketCreatedIdUsed() {
      return this.$store.state.basket.basket?.createdId || null
    },
    basketDefaultUsed() {
      return this.$store.state.basket.basket?.isDefault || null
    },
    userId() {
      return this.$store.state.user.userData?.id || null
    },
    teams() {
      return this.$store.state.user.userData?.teams || []
    },
  },
  methods: {
    async changingBasket(id) {
      try {
        this.loading = true
        await basketChange(id)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async createBasket() {
      try {
        const html = `
          <label for="swal2-name" style="width: 100%;text-align: left;">Введите название:</label>
          <input id="swal2-name" class="swal2-input mb-1">
          ${(this.teams || []).length > 0 ? `
          <label for="swal2-select" style="width: 100%;text-align: left;">Выберите команду:</label>
          <select id="swal2-select" class="swal2-select" style="display: flex;">
            <option value="">Личная</option>
            ${this.teams.map(x => `<option value="${x.id}">${x.title}</option>`)}
          </select>` : ''}`

        const { value: formValues } = await this.$swal.fire({
          title: 'Создание корзины!',
          text: 'Выберите корзину назначения',
          // icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Создать',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          html,
          focusConfirm: false,
          preConfirm: () => ((this.teams || []).length > 0
            ? [
              document.getElementById('swal2-name').value,
              document.getElementById('swal2-select').value,
            ]
            : [
              document.getElementById('swal2-name').value,
              null,
            ]),
        })

        if (formValues) {
          let [title, teamId] = formValues
          title = title.trim().length > 0 ? title.trim() : null
          teamId = !Number.isNaN(parseInt(teamId, 10)) ? parseInt(teamId, 10) : null

          await createBasket({ title, teamId })
          this.$store.commit('app/SET_MESSAGE', 'Корзина успешно создана.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async removeBasket() {
      try {
        const modal = await this.$swal({
          title: 'Подтверждение удаления!',
          text: 'Вы уверены в необходимости удалить корзину? Востановить будет невозможно!',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (modal.value) {
          if (this.basketCreatedIdUsed !== this.userId) throw new Error('У вас нет прав на удаление этой корзины, Вы не являетесь ее создателем')

          await deleteBasket(this.basketIdUsed)
          this.$store.commit('app/SET_MESSAGE', 'Корзина успешно удалена.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
}
</script>
