<template>

  <div class="checkout-items">
    <b-card
      v-for="item in items"
      :key="item.id"
      class="ecommerce-card"
      no-body
    >
      <!-- Product Image -->

      <div class="item-img text-center">
        <b-link :to="{ name: 'product-view', params: { id: item.id } }">
          <b-img
            rounded
            fluid
            :src="item.file && item.file.path ? item.file.path : '/no-image.png'"
          />
        </b-link>
      </div>

      <b-card-body>

        <div class="tool-bar">

          <b-button
            title="переместить"
            variant="flat-dark"
            size="sm"
            class="btn-icon rounded-circle"
            :disabled="item.createdUserId !== userId"
            @click="movingItemFromCart(item.id)"
          >
            <feather-icon
              icon="ShuffleIcon"
              size="20"
            />
          </b-button>

          <b-button
            title="удалить"
            variant="flat-dark"
            size="sm"
            class="btn-icon rounded-circle"
            :disabled="item.createdUserId !== userId"
            @click="removeItemFromCart(item.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="20"
            />
          </b-button>

        </div>

        <!-- Product Details: Card Body -->

        <div class="item-name">
          <h6 class="mb-0 pr-50">
            <b-link class="text-body">
              {{ item.title }}
            </b-link>
          </h6>
          <div class="item-property text-primary font-weight-bolder">
            {{ item.brand }}
          </div>
          <div class="item-property d-none d-md-block">
            {{ item.articul }}
          </div>

        </div>
        <b-row>
          <b-col>
            <div
              v-if="item.actionPrice && item.actionPrice.quantity"
              class="d-flex justify-content-start align-items-center mt-2"
            >
              <b-img :src="boxQuantity" />
              <div class="text-primary">
                +{{ item.actionPrice.quantity.stepProfit }}
              </div>

              <b><div class="bonus-quantity"> - за {{ item.actionPrice.quantity.step }} шт.</div></b>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end align-items-center mt-1">
              <div
                v-if="item.actionPrice.bonus"
                class="price-old text-muted small mr-50"
              >
                <s>{{ item.actionPrice && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B</s>
              </div>
              <div class="price-main font-weight-bold mr-50">
                {{ item.actionPrice.bonus && item.actionPrice.bonus ? item.actionPrice.bonus.profit : item.actionPrice.standard && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B
              </div>
              <b-img
                v-if="item.actionPrice.bonus && item.actionPrice.bonus"
                :src="arrowPrice"
              />
              <b-img :src="homeBonus" />
            </div>

          </b-col>
        </b-row>
      </b-card-body>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink,
  BButton,
  BImg,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'
import { generateRandomVariant } from '@/utils/UserFilter'
import { deleteBasketItem, relocateBasketItem } from '@/application/basketService'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BButton,
    BImg,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      /* eslint-disable global-require */
      arrowPrice: require('@/assets/images/price-group/arrow_price.svg'),
      boxQuantity: require('@/assets/images/price-group/box_quantity.svg'),
      homeBonus: require('@/assets/images/price-group/home_bonus.svg'),
    }
  },
  computed: {
    items() {
      return this.$store.state.basket.basket?.items || []
    },
    basketSelectList() {
      if ((this.$store.state.basket.baskets || []).length === 0 || ((this.$store.state.basket.baskets || []).length > 0 && this.$store.state.basket.baskets.filter(x => x.id !== this.basketUsed).length === 0)) {
        return {}
      }
      const basket = {};
      (this.$store.state.basket.baskets || []).forEach(x => {
        basket[x.id] = x.title
      })
      return basket
    },
    userId() {
      return this.$store.state.user.userData?.id || null
    },
  },
  methods: {
    async removeItemFromCart(id) {
      try {
        const modal = await this.$swal({
          title: 'Подтверждение удаления!',
          text: 'Вы уверены в необходимости удалить элемент? Востановить будет невозможно!',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        if (modal.value) {
          const search = this.items.find(x => x.id === id && x.createdUserId === this.userId)
          if (!search) throw new Error('У вас нет прав на удаление этой позиции, Вы не являетесь автором ее занесения')
          await deleteBasketItem(id)
          this.$store.commit('app/SET_MESSAGE', 'Товар успешно добавлен.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async movingItemFromCart(id) {
      try {
        const { value: multibasketId } = await this.$swal({
          title: 'Перемещение!',
          text: 'Выберите корзину назначения',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Переместить',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          input: 'select',
          inputPlaceholder: 'Выберите корзину',
          inputValidator: value => new Promise(resolve => {
            if (value) {
              resolve()
            } else {
              resolve('Необходимо выбрать корзину')
            }
          }),
          inputOptions: this.basketSelectList,
        })
        if (multibasketId) {
          const search = this.items.find(x => x.id === id && x.createdUserId === this.userId)
          if (!search) throw new Error('У вас нет прав на перемещение этой позиции, Вы не являетесь автором ее занесения')
          await relocateBasketItem({ id, multibasketId })
          this.$store.commit('app/SET_MESSAGE', 'Товар успешно перемещен.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
      generateRandomVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.ecommerce-application {
  .list-view {
    .ecommerce-card {
      grid-template-columns: minmax(102px, 1fr) 3fr;
      // .title-block {
      //   overflow: hidden;
      //   display: grid;
      //   grid-template-columns: 1fr 3fr;
      //   @media(max-width:575.98px) {
      //     grid-template-columns: 1fr 5fr;
      //   }
      // }
      .price-block {
        overflow: hidden;
        display: grid;
        grid-template-columns: 2fr 1fr;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        font-weight: 500;
          .btn {
            // padding: 0.786rem 0.5rem;
            @media(max-width:375.98px) {
              padding: 0.3rem 0.3rem;
              font-size: 12px;
            }
          }
      }
      .item-options {
        justify-content: flex-start;
      }
      .item-property {
        margin-top: 0.2rem !important;
      }
      .item-img {
        padding-left: 1rem;
        text-align: center;
        @media(max-width:575.98px) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .card-body {
        padding-bottom: 0.5rem;
        .item-name {
          padding-right: 60px;
          position: relative;
          button {
            position: absolute;
            top: -10px;
            right: 0;
          }
        }
        .item-price {
          overflow: hidden;
          display: grid;
          grid-template-columns: 2fr 1fr;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
            @media(max-width:375.98px) {
              font-size: 12px;
            }
        }
      }
    }
  }
}
.bonus-quantity {
  @media screen and (max-width: 450px) {
    position: absolute;
    top: 43%;
    white-space: nowrap;
  }
}
.tool-bar {
  position: absolute;
    top: 10px;
    right: 15px;
}
@media(max-width:575.98px) {
  .ecommerce-application {
    .ecommerce-card {
      .item-name {
        h6, .h6 {
          font-size: 0.9rem;
        }
        a {
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
</style>
