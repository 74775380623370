import { render, staticRenderFns } from "./BasketProducts.vue?vue&type=template&id=5b3309db&scoped=true&"
import script from "./BasketProducts.vue?vue&type=script&lang=js&"
export * from "./BasketProducts.vue?vue&type=script&lang=js&"
import style0 from "./BasketProducts.vue?vue&type=style&index=0&id=5b3309db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3309db",
  null
  
)

export default component.exports