<template>
  <div>

    <multi-basket-bar class="d-block d-lg-none mb-1" />

    <div class="list-view product-checkout mt-0">

      <!-- Products List -->
      <basket-products v-if="totalAmount > 0" />
      <p
        v-if="totalAmount === 0"
        class="h4 mb-1"
      >
        Корзина пуста
      </p>

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>
          <multi-basket-bar class="d-none d-lg-block" />
          <div class="price-details">

            <hr class="d-none d-lg-block">

            <h5
              v-if="!basketTicket"
              class="price-title text-center"
            >
              Необходимо сфотографировать кассовый чек
            </h5>

            <div
              v-if="basketTicket"
              class="d-flex justify-content-between align-items-center"
            >

              <b-link
                variant="primary"
                @click="isProfileBasketSideBarActive = true"
              >
                <span>
                  Чек №
                  <b>{{ basketTicket.fiscalDocumentNumber }}</b>
                </span>

                <span>
                  на
                  <b> {{ basketTicket.totalSum }} </b> рублей
                </span>
              </b-link>

              <div class="button-delete-basket-ticket">
                <b-button
                  variant="outline-warning"
                  class="button-delete btn-icon rounded-circle"
                  @click="deleteTicket(basketTicket.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="20"
                  />
                </b-button>
              </div>

            </div>
            <hr>

            <b-img-lazy
              v-if="cashReceipt"
              style="height: 250px;"
              rounded
              :src="cashReceipt.file"
              center
              fluid
            />

            <div class="d-flex justify-content-between align-items-center my-1">
              <b>
                Количество
              </b>

              <div class="text-primary font-weight-bolder">
                {{ totalAmount }}
              </div>
            </div>

            <input
              id="input-file-cash-receipt"
              type="file"
              style="display: none;"
              @change="onSubmit"
            >
            <b-dropdown
              v-if="!basketTicket"
              block
              split
              split-variant="outline-primary"
              text="Добавить чек"
              variant="primary"
              class="m-2"
              @click="addFile"
            >
              <b-dropdown-item
                @click="createElement(basketId)"
              >
                <div class="text-nowrap">
                  <span>Заполнить данные чека вручную</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-if="basketTicket"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="primary"
              block
              :disabled="totalAmount === 0"
              @click="createOrder"
            >
              Оформить продажу
            </b-button>
          </div>

          <!-- боковая панель создания чека вручную -->
          <ticket-sidebar
            :is-ticket-sidebar-active.sync="isTicketSidebarActive"
            :open-element-id="openElementId"
          />
          <!-- *** -->

          <!-- боковая панель чека -->
          <profile-basket-side-bar
            v-if="basketTicket"
            :is-profile-basket-side-bar-active.sync="isProfileBasketSideBarActive"
            :ticket="basketTicket"
            :open-element-id="basketTicket.id"
          />
          <!-- *** -->

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BImgLazy,
  BDropdown,
  BDropdownItem,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { generateRandomVariant } from '@/utils/UserFilter'

import {
  addCashReceipt, deleteCashReceipt, createOrder, deleteBasketTicket,
} from '@/application/basketService'

import { ref } from '@vue/composition-api'
import ProfileBasketSideBar from '@/components/basket/ProfileBasketSideBar.vue'
import BasketProducts from './BasketProducts.vue'
import MultiBasketBar from '../../components/basket/MultiBasketBar.vue'
import TicketSidebar from './components/TicketSidebar.vue'

export default {
  components: {
    BCard,
    BButton,
    BasketProducts,
    MultiBasketBar,
    BImgLazy,
    BDropdown,
    BLink,

    TicketSidebar,
    ProfileBasketSideBar,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    totalAmount() {
      return (this.$store.state.basket.basket?.items || []).length
    },
    basketId() {
      return this.$store.state.basket.basket?.id || null
    },
    cashReceipt() {
      return this.$store.state.basket.basket?.cashReceipt || null
    },
    basketTicket() {
      return this.$store.state.basket.basket?.basketTicket || null
    },
  },

  methods: {
    async deleteTicket(ticketId) {
      const confirm = await this.$swal({
        title: 'Подтверждение удаления!',
        text: 'Вы уверены в необходимости удаления чека? Востановить невозможно!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Удалить',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      const { isConfirmed } = confirm
      if (isConfirmed) deleteBasketTicket(ticketId)
    },

    async addFile() {
      const input = document.getElementById('input-file-cash-receipt')
      input.click()
    },
    async onSubmit(e) {
      try {
        this.loading = true
        const file = e.target.files[0]
        const param = new FormData()
        param.append('file', file)
        param.append('id', this.basketId)
        await addCashReceipt(param)
        this.$store.commit('app/SET_MESSAGE', 'Кассовый чек успешно добавлен.')
      } catch (error) {
        this.loading = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },

  },
  setup() {
    const openElementId = ref(null)
    const isTicketSidebarActive = ref(false)
    const isProfileBasketSideBarActive = ref(false)

    const createElement = id => {
      openElementId.value = id
      isTicketSidebarActive.value = true
    }
    return {
      generateRandomVariant,
      createOrder,
      deleteCashReceipt,
      isTicketSidebarActive,
      isProfileBasketSideBarActive,
      openElementId,
      createElement,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>

.ecommerce-application {
    .button-delete-basket-ticket {
      background-color: #fff;
      border-radius: 100px;
    }
}
</style>
