var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"ticket-sidebar","visible":_vm.isTicketSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"shown":function($event){return _vm.onShownSidebar()},"hidden":_vm.onHiddenSidebar,"change":function (val) { return _vm.$emit('update:is-ticket-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Кассовый чек ")])]),_c('b-overlay',{attrs:{"rounded":"pill","show":_vm.isLoading}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit.bind(null, _vm.openElementId))},"reset":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_c('b-form-group',{attrs:{"label":"Данные чека"}},[_c('b-row',[_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"номер фискального накопителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fiscal-drive-number","placeholder":"Номер фискального накопителя (ФН)","autocomplete":"off","autofocus":"","trim":""},model:{value:(_vm.elementData.fiscalDriveNumber),callback:function ($$v) {_vm.$set(_vm.elementData, "fiscalDriveNumber", $$v)},expression:"elementData.fiscalDriveNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"номер фискального документа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fiscal-document-number","placeholder":"Номер фискального документа (ФД)","type":"number","autocomplete":"off","trim":""},model:{value:(_vm.elementData.fiscalDocumentNumber),callback:function ($$v) {_vm.$set(_vm.elementData, "fiscalDocumentNumber", $$v)},expression:"elementData.fiscalDocumentNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"фискальный признак документа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fiscal-sign","placeholder":"Фискальный признак документа (ФП)","type":"number","autocomplete":"off","trim":""},model:{value:(_vm.elementData.fiscalSign),callback:function ($$v) {_vm.$set(_vm.elementData, "fiscalSign", $$v)},expression:"elementData.fiscalSign"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('validation-provider',{attrs:{"name":"сумма чека","rules":{ required: _vm.required, regex: /^[1-9]\d+(\.\d{2})*(,\d{2})?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"bg-primary text-white h-100"},[_vm._v(" ₽ ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"total-sum","placeholder":"Сумма чека","autocomplete":"off","trim":""},on:{"input":function($event){_vm.elementData.totalSum = _vm.currencyUnFormat(_vm.totalSum)}},model:{value:(_vm.totalSum),callback:function ($$v) {_vm.totalSum=$$v},expression:"totalSum"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"sm":"10"}},[_c('b-row',{staticClass:"d-flex justify-content-around"},[_c('validation-provider',{staticClass:"pr-1 col-7",attrs:{"rules":"required","name":"дата"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","type":"date","max":_vm.toDay,"trim":""},model:{value:(_vm.elementData.date),callback:function ($$v) {_vm.$set(_vm.elementData, "date", $$v)},expression:"elementData.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"pl-1 col-5",attrs:{"rules":"required","name":"время"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time","type":"time","trim":""},model:{value:(_vm.elementData.time),callback:function ($$v) {_vm.$set(_vm.elementData, "time", $$v)},expression:"elementData.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Сохранить ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Отмена ")])],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }