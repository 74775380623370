<template>
  <b-sidebar
    id="profile-basket-side-bar"
    :visible="isProfileBasketSideBarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-profile-basket-side-bar-active', val)"
    @hidden="onHiddenSidebar"
  >

    <template v-slot="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Свойства чека
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div>

        <validation-observer
          v-slot="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit.bind(null, openElementId))"
            @reset.prevent="clearForm"
          >

            <b-form-group label="Данные чека">
              <b-row>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="номер фискального накопителя"
                    rules="required"
                  >
                    <label for="input-live">Номер фискального накопителя (ФН)</label>

                    <b-form-input
                      id="fiscal-drive-number"
                      v-model="basketTicket.fiscalDriveNumber"
                      autocomplete="off"
                      autofocus
                      trim
                      :disabled="!edit"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="номер фискального документа"
                    rules="required"
                  >
                    <label for="input-live">Номер фискального документа (ФД)</label>

                    <b-form-input
                      id="fiscal-document-number"
                      v-model="basketTicket.fiscalDocumentNumber"
                      type="number"
                      autocomplete="off"
                      trim
                      :disabled="!edit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="фискальный признак документа"
                    rules="required"
                  >
                    <label for="input-live">Фискальный признак документа (ФП)</label>

                    <b-form-input
                      id="fiscal-sign"
                      v-model="basketTicket.fiscalSign"
                      type="number"
                      autocomplete="off"
                      trim
                      :disabled="!edit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >

                  <validation-provider
                    v-slot="{ errors }"
                    name="сумма чека"
                    :rules="{ required, regex: /^[1-9]+(\.\d{2})*(,\d{2})?$/ }"
                  >
                    <label for="input-live">Сумма чека</label>

                    <b-input-group>
                      <template #append>
                        <b-input-group-text class="bg-primary text-white h-100">
                          ₽
                        </b-input-group-text>
                      </template>

                      <b-form-input
                        id="total-sum"
                        v-model="basketTicket.totalSum"
                        autocomplete="off"
                        trim
                        :disabled="!edit"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>

                <b-col
                  sm="10"
                  class="mb-50"
                >
                  <label for="input-live">Дата</label>

                  <b-row class="d-flex justify-content-around">

                    <validation-provider
                      v-slot="{ errors }"
                      class="pr-1 col-7"
                      rules="required"
                      name="дата"
                    >

                      <b-form-input
                        id="date"
                        v-model="basketTicket.receiveDate[0]"
                        type="date"
                        :max="toDay"
                        trim
                        :disabled="!edit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      class="pl-1 col-5"
                      rules="required"
                      name="время"
                    >
                      <b-form-input
                        id="time"
                        v-model="basketTicket.receiveDate[1]"
                        type="time"
                        trim
                        :disabled="!edit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-row>
                </b-col>

              </b-row>

            </b-form-group>

            <div
              v-if="isAdmin && (!edit && Number.isNaN(parseInt(basketTicket.companyId, 10)))"
              class="d-flex"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="button"
                @click.prevent="edit = true"
              >
                Редактировать
              </b-button>
            </div>

            <div
              v-if="edit && isAdmin"
              class="d-flex"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Сохранить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </div>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BInputGroup,
  BInputGroupText,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from 'vee-validate'

import ru from 'vee-validate/dist/locale/ru.json'
import Ripple from 'vue-ripple-directive'
import { regex } from 'vee-validate/dist/rules'
import { required } from '@core/utils/validations/validations'
import { updateBasketTicket } from '@/application/basketService'
import { toISODateNowFormat } from '@/@core/utils/utils'
import { clearDate } from '@/@core/utils/filter'

localize('ru', ru)
extend('regex', regex)

export default {
  components: {
    BSidebar,
    BForm,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPanelPayoutModerationEditActive',
    event: 'update:is-panel-payout-moderation-edit-active',
  },
  props: {
    isProfileBasketSideBarActive: {
      type: Boolean,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
      default: () => {},
    },
    openElementId: {
      type: Number,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalSum: '',
      toDay: toISODateNowFormat(),
    }
  },
  computed: {
    basketTicket() {
      const { receiveDate, totalSum, ...etc } = this.ticket
      Object.assign(this.ticket, { receiveDate: clearDate(receiveDate), totalSum, ...etc })
      return this.ticket
    },
  },
  setup({ ticket }, { emit }) {
    const {
      isLoading,
      optionsTasksType,
      typeOptions,
      permissionActionOptions,
      permissionModulesOptions,
      edit,

      refFormObserver,
      getValidationState,
      clearForm,

      onHiddenSidebar,
      onSubmit,
    } = updateBasketTicket(emit, ticket)

    return {
      isLoading,
      optionsTasksType,
      typeOptions,
      permissionActionOptions,
      permissionModulesOptions,
      edit,

      refFormObserver,
      getValidationState,
      clearForm,

      onHiddenSidebar,
      onSubmit,
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
